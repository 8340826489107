<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑规则':'新增规则'"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="故障字典" prop="faultDictId">
        <vm-select-input :value="model.faultDictId" @select="$refs.faultSelect.open()"></vm-select-input>
      </el-form-item>
      <el-form-item label="故障名称" prop="faultName">
        <el-input v-model="model.faultName" :disabled="true" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="故障代码" prop="faultCode">
        <el-input v-model="model.faultCode" :disabled="true" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="故障集" prop="iotFaultSet">
        <vm-dict-select v-model.trim="model.iotFaultSet" :disabled="true" type="iotFaultSet"></vm-dict-select>
      </el-form-item>
      <!--      <el-form-item label="故障处理规则描述" prop="manufacturerName">-->
      <!--        <el-input v-model="model.description" type="manufacturerName"></el-input>-->
      <!--      </el-form-item>-->
      <el-form-item label="故障等待时间" prop="faultWaitTime">
        <el-input-number v-model="model.faultWaitTime"></el-input-number>
        单位/秒
      </el-form-item>
      <el-form-item label="故障状态" prop="faultWaitTime">
        <el-radio-group v-model="model.radio" @change="$forceUpdate()">
          <el-radio label="ifAfterWaitFaultAlive">如果在等待时间后故障仍在</el-radio>
          <!--          <el-radio disabled label="ifAfterWaitFaultRemoval">如果在等待时间后故障移除</el-radio>-->
        </el-radio-group>
      </el-form-item>

      <el-form-item label="故障重复次数达到" prop="faultWaitTime">
        <el-input-number v-model="model.ifAfterWaitFaultMoreThan"></el-input-number>
      </el-form-item>
      <!--      <el-form-item label="故障重复次数不足" prop="faultWaitTime">-->
      <!--        <el-input-number v-model="model.ifAfterWaitFaultLessThan" disabled></el-input-number>-->
      <!--      </el-form-item>-->
      <el-form-item label="处理方式" prop="faultWaitTime">
        <el-radio-group v-model="model.radio1" @change="$forceUpdate()">
          <el-radio label="thenRepair">转维修</el-radio>
          <el-radio label="thenMaintain" disabled>转保养</el-radio>
          <el-radio label="thenIgnore">忽略</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
    <faultSelect ref="faultSelect" @select="faultSelect"></faultSelect>
  </el-dialog>
</template>
<script>
  import faultSelect from "@/views/basic/faultSelect";

  export default {
    components: {faultSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          thenIgnore:false,
          thenMaintain:false,
          thenRepair:false,
          ifAfterWaitFaultAlive:false,
          ifAfterWaitFaultRemoval:false,
          ifAfterWaitFaultMoreThan:-1,
          ifAfterWaitFaultLessThan:-1,
          radio:"ifAfterWaitFaultAlive",
        },

      };

    },
    methods: {
      faultSelect(row) {
        this.$set(this.model,"faultDictId",row.id);
        this.$set(this.model,"faultName",row.faultName);
        this.$set(this.model,"faultCode",row.faultCode);
        this.$set(this.model,"iotFaultSet",row.iotFaultSet);
        this.$set(this.model,"faultHandleRuleDesc",row.faultHandleRule);
      },
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`/order-create-config/repair/${this.model.id}`).then(data => {

            this.contentLoading = false;
            console.log(data,104);

            this.model = data;
            this.model.radio=data.ifAfterWaitFaultAlive?"ifAfterWaitFaultAlive":"ifAfterWaitFaultRemoval";
            if (data.thenIgnore) {
              this.model.radio1="thenIgnore";
            }else if (data.thenMaintain) {
              this.model.radio1="thenMaintain";
            }else if (data.thenRepair) {
              this.model.radio1="thenRepair";
            }
          });
        }
      },
      handleSubmit() {
        this.model= {...this.model,
                     thenIgnore:false,
                     thenMaintain:false,
                     thenRepair:false,
                     ifAfterWaitFaultAlive:false,
                     ifAfterWaitFaultRemoval:false,
        },
        this.model[this.model.radio] = true;
        this.model[this.model.radio1] = true;
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            if (!this.model.id) {
              this.$http
                .save("/order-create-config/repair", this.model)
                .then(() => {
                  this.submitLoading = false;
                  this.dialogVisible = false;
                  this.$emit("save-success");
                  this.$message.success("保存成功");
                })
                .catch(() => {
                  this.submitLoading = false;
                });}                        else {
              this.$http
                .put(`/order-create-config/repair/${this.model.id}`, this.model)
                .then(() => {
                  this.submitLoading = false;
                  this.dialogVisible = false;
                  this.$emit("save-success");
                  this.$message.success("保存成功");
                })
                .catch(() => {
                  this.submitLoading = false;
                });
            }
          }else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }

        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>

<template>
  <div>
    <el-tabs active-name="zero" @tab-click="change">
      <el-tab-pane v-if="$auth('工单生成配置维修工单')" label="维修工单" name="zero">
        <vm-table
          ref="vmTable"
          :filter.sync="filter"
          url="order-create-config/repair/page">
          <!--      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">{{$l("common.add", "新增")}}</el-button>-->
          <!--      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">{{$l("common.add", "从合同中导入")}}</el-button>-->
          <el-button
            v-if="$auth('维修工单新增')"
            slot="toolbar"
            type="primary"
            size="mini"
            @click="$refs.repairEdit.open(0)">
            {{$l("common.add", "新增")}}
          </el-button>

          <template slot="adSearch">
            <div class="vm-search">
              <vm-search :label="$l('user.name','故障代码')">
                <el-input v-model.trim="filter.faultCode" clearable></el-input>
              </vm-search>
              <vm-search :label="$l('user.type','故障名称')">
                <el-input v-model.trim="filter.faultName" type="userType"></el-input>
              </vm-search>
              <vm-search :label="$l('user.type','故障集')">
                <vm-dict-select v-model.trim="filter.iotFaultSet" type="iotFaultSet"></vm-dict-select>
              </vm-search>
            </div>
          </template>

          <el-table-column align="center" type="index" width="50"></el-table-column>
          <el-table-column prop="faultName" :label="$l('project.name','故障名称')"></el-table-column>
          <el-table-column prop="faultCode" label="故障代码"></el-table-column>
          <el-table-column prop="iotFaultSetDesc" label="故障集"></el-table-column>
          <el-table-column prop="faultHandleRuleDesc" label="故障处理规则描述">
          </el-table-column>
          <el-table-column prop="faultWaitTime" label="故障的等待时间(秒)">
          </el-table-column>
          <el-table-column prop="ifAfterWaitFaultAlive" label="如果在等待时间后故障仍在" align="center">
          </el-table-column>
          <el-table-column prop="ifAfterWaitFaultMoreThan" label="如果在等待时间后故障重复次数达到" align="center"></el-table-column>
          <el-table-column prop="ifAfterWaitFaultRemoval" label="如果在等待时间后故障移除" align="center"></el-table-column>
          <el-table-column prop="ifAfterWaitFaultLessThan" label="如果在等待时间后故障重复次数不足" align="center"></el-table-column>
          <el-table-column prop="thenRepair" label="转维修" align="center">
            <template slot-scope="scope">
              {{scope.row.thenRepair?"是":"否"}}
            </template>
          </el-table-column>
          <el-table-column prop="thenMaintain" label="转保养" align="center">
            <template slot-scope="scope">
              {{scope.row.thenMaintain?"是":"否"}}
            </template>
          </el-table-column>
          <el-table-column prop="thenIgnore" label="忽略" align="center">
            <template slot-scope="scope">
              {{scope.row.thenIgnore?"是":"否"}}
            </template>
          </el-table-column>
          <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
            <template slot-scope="scope">
              <el-button v-if="$auth('维修工单编辑')" type="primary" @click="$refs.repairEdit.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
              <el-button v-if="$auth('维修工单删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
            </template>
          </el-table-column>
        </vm-table>
      </el-tab-pane>
      <el-tab-pane v-if="$auth('工单生成配置按期维保工单')" label="按期维保工单" name="first">
        <div style="margin-bottom: 20px">计划保养日期前<el-input-number v-model="model.regulation.planToOrderBeforeDays" style="width: 200px"></el-input-number>天生成工单，并通知对应班组成员</div>
        <div style="margin-bottom: 20px">据上次保养日期超过<el-input-number v-model="model.regulation.maxIntervalDays" style="width: 200px"></el-input-number>天未完成保养，则工单超期，通知维保经理<vm-select-input style="width: 200px" :value="model.regulation.overdueAssigneeName" @select="$refs.userSelectWb.open()"></vm-select-input></div>
        <div>
          <el-button v-if="$auth('按期维保工单保存')" type="primary" @click="submitDate">保存</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="$auth('工单生成配置按需维保工单')" label="按需维保工单" name="second">
        <table class="tb" cellspacing="0" cellpadding="0" style="border-collapse:collapse;">
          <tr>
            <td class="content" :rowspan="3">电梯健康状况</td>
            <td class="content" :colspan="8">电梯累计运行数据阈值</td>
          </tr>
          <tr>
            <td class="content" :colspan="2">电梯运行次数</td>
            <td class="content" :colspan="2">电梯运行时间</td>
            <td class="content" :colspan="2">电梯运行距离</td>
            <td class="content" :colspan="2">电梯开门次数</td>
          </tr>
          <tr>
            <td class="content">阈值起点(单位次)</td>
            <td class="content">阈值终点(单位次)</td>
            <td class="content">阈值起点</td>
            <td class="content">阈值终点</td>
            <td class="content">阈值起点(单位米)</td>
            <td class="content">阈值终点(单位米)</td>
            <td class="content">阈值起点(单位次)</td>
            <td class="content">阈值终点(单位次)</td>
          </tr>
          <tr>
            <td class="content">A</td>
            <td class="content"><el-input-number v-model="model.asNeed.arunningCountThresholdFrom" style="width: 150px"></el-input-number></td>
            <td class="content"><el-input-number v-model="model.asNeed.arunningCountThresholdTo" style="width: 150px"></el-input-number></td>
            <td class="content"><el-input-number v-model="model.asNeed.arunningTimeThresholdFrom" disabled style="width: 150px"></el-input-number></td>
            <td class="content"><el-input-number v-model="model.asNeed.arunningTimeThresholdTo" disabled style="width: 150px"></el-input-number></td>
            <td class="content"><el-input-number v-model="model.asNeed.arunningDistanceThresholdFrom" style="width: 150px"></el-input-number></td>
            <td class="content"><el-input-number v-model="model.asNeed.arunningDistanceThresholdTo" style="width: 150px"></el-input-number></td>
            <td class="content"><el-input-number v-model="model.asNeed.adoorOpeningThresholdFrom" style="width: 150px"></el-input-number></td>
            <td class="content"><el-input-number v-model="model.asNeed.adoorOpeningThresholdTo" style="width: 150px"></el-input-number></td>
          </tr>
        </table>
        <div>
          如果电梯自上次保养完成后累计的任意运行数据达到阈值起点，
          则生成按需的保养工单，并通知对应班组所有成员
        </div>
        <div>
          如果电梯自上次保养完成后累计的任意运行数据达到阈值终点，
          则工单超期，通知维保经理<vm-select-input style="width: 200px" :value="model.asNeed.overdueAssigneeName" @select="$refs.userSelect.open()"></vm-select-input>
        </div>
        <div>
          <el-button v-if="$auth('按需维保工单保存')" style="margin-top: 20px" type="primary" @click="submitAsNeed">保存</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <user-select ref="userSelectWb" @select="selectUserWb($event)"></user-select>
    <user-select ref="userSelect" @select="selectUser($event)"></user-select>
    <repairEdit ref="repairEdit" @save-success="getRepair(-1)"></repairEdit>
  </div>
</template>

<script>
  import repairEdit from "@/views/maintenance/maintenance/maintenancePlan/repairEdit";
  import UserSelect from "@/views/user/UserSelect";
  export default {
    name: "MaintenanceOrderConfig",
    components:{UserSelect,repairEdit},
    data() {
      return{
        model:{
          regulation:{},
          asNeed:{},
        },
        filter: {
          phone: "",
          name: "",
          type: "",
          faultCode:"",
          status: "",
        },
      };
    },
    mounted() {
      this.getDateData();
      this.getRequireData();
      this.getRepair(1);
    },
    methods:{
      change() {
        this.getDateData();
        this.getRequireData();
        this.getRepair();
      },
      getRepair(num) {
        this.$refs.vmTable.getList(num);
      },
      getDateData() {
        this.$http.get("/order-create-config/regulation-maintain").then(res=>{
          this.model.regulation=res;
        });
      },
      getRequireData() {
        this.$http.get("/order-create-config/as-need-maintain").then(res=>{
          this.model.asNeed=res;
        });
      },
      selectUserWb(row) {
        this.model.regulation.overdueAssigneeId = row.id;
        this.model.regulation.overdueAssigneeName = row.name;
      },
      selectUser(row) {
        this.model.asNeed.overdueAssigneeId = row.id;
        this.model.asNeed.overdueAssigneeName = row.name;
      },
      submitDate() {
        this.$http.post("/order-create-config/regulation-maintain",this.model.regulation).then(()=>{
          this.$message.success("保存成功");
          this.getDateData();
        });
      },
      submitAsNeed() {
        this.$http.post("/order-create-config/as-need-maintain",this.model.asNeed).then(()=>{
          this.$message.success("保存成功");
          this.getRequireData();
        });
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.faultName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("/order-create-config/repair", row.id).then(() => {
            this.getRepair(-1);
            this.$message.success(row.faultName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>

<style scoped>
.content{
  width: 200px;
  border: 1px solid black;
  text-align: center;
}
</style>
